import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ColorSwatches } from "../docz-components/Swatches";
import { Flex } from "../components/Flex";
import { colors } from "./colors";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "colors"
    }}>{`Colors`}</h1>
    <h2 {...{
      "id": "primaries"
    }}>{`Primaries`}</h2>
    <h3 {...{
      "id": "contextual-primary-colors"
    }}>{`Contextual Primary colors`}</h3>
    <p>{`Primary colors react to the context that is set in `}<inlineCode parentName="p">{`HoppinDesignProvider`}</inlineCode>{`
Wherever possible use `}<inlineCode parentName="p">{`primary`}</inlineCode>{` and set the context in the HoppinDesignProvider that wrappes that part of the app.`}</p>
    <ColorSwatches colors={colors} context="primary" mdxType="ColorSwatches" />
    <h3 {...{
      "id": "shadower-colors"
    }}>{`Shadower Colors`}</h3>
    <p>{`These colors are assigned to `}<inlineCode parentName="p">{`primary`}</inlineCode>{` when `}<inlineCode parentName="p">{`context='shadower'`}</inlineCode>{` on the `}<inlineCode parentName="p">{`HoppinDesignProvider`}</inlineCode></p>
    <ColorSwatches colors={colors} context="shadower" mdxType="ColorSwatches" />
    <h3 {...{
      "id": "host-colors"
    }}>{`Host Colors`}</h3>
    <p>{`These colors are assigned to `}<inlineCode parentName="p">{`primary`}</inlineCode>{` when `}<inlineCode parentName="p">{`context='host'`}</inlineCode>{` on the `}<inlineCode parentName="p">{`HoppinDesignProvider`}</inlineCode></p>
    <ColorSwatches colors={colors} context="host" mdxType="ColorSwatches" />
    <h2 {...{
      "id": "neutral-colors"
    }}>{`Neutral colors`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`neutral`}</inlineCode>{` for body text, backgrounds, etc.`}</p>
    <ColorSwatches colors={colors} context="neutral" mdxType="ColorSwatches" />
    <h2 {...{
      "id": "whites"
    }}>{`Whites`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`whiteout`}</inlineCode>{` for backgrounds. They have a very subtle gold tint to contrast with the blue hue of the neutral.`}</p>
    <ColorSwatches colors={colors} context="whiteout" mdxType="ColorSwatches" />
    <h2 {...{
      "id": "state-palettes"
    }}>{`State Palettes`}</h2>
    <p>{`The following colors are to be used very selectively to highlight a specific state. Errors, warnings, etc.`}</p>
    <h3 {...{
      "id": "danger"
    }}>{`Danger`}</h3>
    <ColorSwatches colors={colors} context="danger" mdxType="ColorSwatches" />
    <h3 {...{
      "id": "warning"
    }}>{`Warning`}</h3>
    <ColorSwatches colors={colors} context="warning" mdxType="ColorSwatches" />
    <h3 {...{
      "id": "info"
    }}>{`Info`}</h3>
    <ColorSwatches colors={colors} context="info" mdxType="ColorSwatches" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      